export default [
  // {
  //   name: "envMap",
  //   type: "cubeTexture",
  //   path: [
  //     "/textures/environmentMaps/5/px.png",
  //     "/textures/environmentMaps/5/nx.png",
  //     // "/textures/environmentMaps/5/py.png",
  //     "/textures/environmentMaps/5/ny.png",
  //     "/textures/environmentMaps/5/pz.png",
  //     "/textures/environmentMaps/5/nz.png",
  //   ],
  // },
  // {
  //   name: "envMapVenice",
  //   type: "exr",
  //   path: "/textures/environmentMaps/venice_sunset_1k.exr",
  // },
];
