import Experience from "./Experience/Experience.js";

const experience = new Experience({
  targetElement: document.querySelector(".experience"), // the container for the 3D Experience -> on Chopard: the popin
  canvas: document.querySelector("canvas.webgl"), // the Canvas to draw the experiment, just to add some css
  model: {
    name: "watchModel",
    type: "gltfModel",
    path: "https://dzmorc4oz-ressh.cloudinary.com/image/upload/v1730900731/test_revamp/product/alpine_eagle.glb",
  },
  environment: {
    name: "envMap",
    type: "cubeTexture",
    path: [
      "/textures/environmentMap/px.png",
      "/textures/environmentMap/nx.png",
      "/textures/environmentMap/py.png",
      "/textures/environmentMap/ny.png",
      "/textures/environmentMap/pz.png",
      "/textures/environmentMap/nz.png",
    ],
  },
});
