import * as THREE from "three";
import Experience from "../Experience";
import { gsap } from "gsap";
export default class Watch {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.time = this.experience.time;
    this.resources = this.experience.resources;

    // Options
    this.options = {
      animate: this.animateWatch.bind(this),
    };

    // Setup
    this.resource = this.resources.items.watchModel;

    this.setModel();
    this.setAnimation();

    // Debug
    this.setDebug();

    // Get the range slider element
    const rangeSlider = document.getElementById("rangeSlider");

    // Update the timeline's progress based on the slider value
    rangeSlider.addEventListener("input", (e) => {
      const progress = e.target.value / 100; // Assume slider has values from 0 to 100
      this.tl.progress(progress); // Set the timeline's progress based on the slider
    });
  }

  setModel() {
    this.model = this.resource.scene;

    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        // Hours
        if (child.name === "网格001") {
          this.hoursHand = child;
        }
        if (child.name === "网格001_1") {
          this.hoursHandWhite = child;
        }
        // Minutes
        if (child.name === "网格003") {
          this.minutesHand = child;
        }
        if (child.name === "网格003_1") {
          this.minutesHandWhite = child;
        }
        // Seconds
        if (child.name === "S") {
          this.secondsHand = child;
        }
        // Pivot
        if (child.name === "轴心") {
          this.pivot = child;
        }
        // // numbers
        // if (child.name === "网格049_1") {
        //   this.numbersWhite = child;
        // }
        // if (child.name === "网格049_1") {
        //   this.numbersWhite = child;
        // }
        // Screws
        if (child.name === "表盘铆钉") {
          this.screws = child;
        }
        // Dial
        if (child.name === "网格023") {
          this.dialTop = child;
        }
        if (child.name === "网格023_1") {
          this.dial = child;
        }
        // Crown
        if (child.name === "niu_1") {
          this.crown = child;
        }
        if (child.name === "niu") {
          this.crownOne = child;
        }
        if (child.name === "niu_2") {
          this.crownTwo = child;
        }
        if (child.name === "八角心") {
          this.crownStar = child;
        }

        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
    this.model.scale.set(0.09, 0.09, 0.09);
    this.scene.add(this.model);
  }

  animateWatch() {
    console.log(this.tl.reversed());
    if (this.tl.progress() === 0 || this.tl.reversed()) {
      this.tl.play();
    } else {
      this.tl.reverse();
    }
  }

  setAnimation() {
    this.setHandsRotation();
    setInterval(() => {
      this.setHandsRotation();
    }, 1000);
    gsap.to(this.secondsHand.rotation, {
      duration: 60,
      z: -Math.PI * 2,
      repeat: -1,
      ease: "none",
    });

    // create timeline
    this.tl = gsap.timeline();
    this.tl.pause();

    const timelineOptions = {
      minimumDistance: 20,
      duration: 1,
    };
    // Seconds animation (add with a small stagger after minutes)
    this.tl
      .addLabel("seconds") // starts 1.5 seconds before the end of minutes
      .to(
        this.secondsHand.position,
        {
          z: this.secondsHand.position.z + timelineOptions.minimumDistance + 6,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "seconds"
      );
    // Minutes animation (animate both hands together with a label)
    this.tl
      .addLabel("minutes", "-=1") // starts 1.5 seconds before the end of hours
      .to(
        this.minutesHandWhite.position,
        {
          z:
            this.minutesHandWhite.position.z +
            timelineOptions.minimumDistance +
            4,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "minutes"
      )
      .to(
        this.minutesHand.position,
        {
          z: this.minutesHand.position.z + timelineOptions.minimumDistance + 4,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "minutes"
      );
    // Hours animation (animate both hands together with a label)
    this.tl
      .addLabel("hours", "-=1")
      .to(
        this.hoursHandWhite.position,
        {
          z:
            this.hoursHandWhite.position.z +
            timelineOptions.minimumDistance +
            2,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "hours"
      )
      .to(
        this.hoursHand.position,
        {
          z: this.hoursHand.position.z + timelineOptions.minimumDistance + 2,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "hours"
      );
    // Pivot
    this.tl.addLabel("pivot", "-=1").to(
      this.pivot.position,
      {
        z: this.pivot.position.z + timelineOptions.minimumDistance,
        duration: timelineOptions.duration,
        ease: "power2.inOut",
      },
      "pivot"
    );
    // Screws
    this.tl.addLabel("screws", "-=1").to(
      this.screws.position,
      {
        z: this.screws.position.z + timelineOptions.minimumDistance - 2,
        duration: timelineOptions.duration,
        ease: "power2.inOut",
      },
      "screws"
    );
    // Dial
    this.tl.addLabel("dial", "-=1").to(
      this.dialTop.position,
      {
        z: this.dialTop.position.z + timelineOptions.minimumDistance - 8,
        duration: timelineOptions.duration,
        ease: "power2.inOut",
      },
      "dial"
    );
    this.tl.addLabel("dial", "-=1").to(
      this.dial.position,
      {
        z: this.dial.position.z + timelineOptions.minimumDistance - 8,
        duration: timelineOptions.duration,
        ease: "power2.inOut",
      },
      "dial"
    );
    // Crown
    //
    this.tl.addLabel("crown", "-=1").to(
      this.crown.position,
      {
        x: this.crown.position.x + 8,
        duration: timelineOptions.duration,
        ease: "power2.inOut",
      },
      "crown"
    );
    this.tl.addLabel("crown", "-=1").to(
      this.crownOne.position,
      {
        x: this.crownOne.position.x + 8,
        duration: timelineOptions.duration,
        ease: "power2.inOut",
      },
      "crown"
    );
    this.tl.addLabel("crown", "-=1").to(
      this.crownTwo.position,
      {
        x: this.crownTwo.position.x + 8,
        duration: timelineOptions.duration,
        ease: "power2.inOut",
      },
      "crown"
    );
    this.tl.addLabel("crown", "-=1").to(
      this.crownStar.position,
      {
        x: this.crownStar.position.x + 8,
        duration: timelineOptions.duration,
        ease: "power2.inOut",
      },
      "crown"
    );
  }

  setHandsRotation() {
    let date = new Date();
    let minutes = date.getMinutes();
    let hours = date.getHours();

    this.hoursHand.rotation.z = -THREE.MathUtils.degToRad(
      hours * 30 + minutes / 2
    );
    this.hoursHandWhite.rotation.z = -THREE.MathUtils.degToRad(
      hours * 30 + minutes / 2
    );
    this.minutesHand.rotation.z = -THREE.MathUtils.degToRad(minutes * 6);
    this.minutesHandWhite.rotation.z = -THREE.MathUtils.degToRad(minutes * 6);
  }

  setDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Watch");
      this.debugFolder.add(this.options, "animate");
    }
  }

  update() {}
}
